import {openIAKey} from './constKeys'

export const newThread = async () => {
  const response = await fetch('https://api.openai.com/v1/threads', {
    method: 'POST',
    headers: {
      'OpenAI-Beta': 'assistants=v1',
      'Authorization': `Bearer ${openIAKey}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({})
  });

  return (await response.json()).id;
}


export const uploadMessage = async (threadId, assistant_id, message) => {
  // return undefined
  await fetch(`https://api.openai.com/v1/threads/${threadId}/messages`, {
    method: 'POST',
    headers: {
      'OpenAI-Beta': 'assistants=v1',
      'Authorization': `Bearer ${openIAKey}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      role: 'user',
      content: message
    })
  });

  const creationResponse = await fetch(`https://api.openai.com/v1/threads/${threadId}/runs`, {
    method: 'POST',
    headers: {
      'OpenAI-Beta': 'assistants=v1',
      'Authorization': `Bearer ${openIAKey}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ assistant_id })
  });

  // getThe response of assistant
  // const creationData = await creationResponse.json();
  // const runId = creationData.id;
  return creationResponse
}

export const getMessage = async (threadId, runId) => {
  async function waiting() {
    const statusResponse = await fetch(`https://api.openai.com/v1/threads/${threadId}/runs/${runId}`, {
      method: 'GET',
      headers: {
        'OpenAI-Beta': 'assistants=v1',
        'Authorization': `Bearer ${openIAKey}`,
        'Content-Type': 'application/json'
      }
    });
    const statusData = await statusResponse.json();

    if (!['queued', 'in_progress'].includes(statusData.status)) {
      // console.log('the status is:', statusData.status);
      return;
    }

    await new Promise(resolve => setTimeout(resolve, 1000));
    await waiting();
  }
  await waiting();

  const response = await fetch(`https://api.openai.com/v1/threads/${threadId}/messages`, {
    method: 'GET',
    headers: {
      'OpenAI-Beta': 'assistants=v1',
      'Authorization': `Bearer ${openIAKey}`,
      'Content-Type': 'application/json'
    }
  });
  // console.log('response', response);
  const data = await response.json();

  // console.log("Data final: ", data);
  return data.data[0].content[0].text.value;
}
