<script>
import ChatLayout from './ChatLayout.vue';
import { newThread, uploadMessage, getMessage } from './useAssistantAI'

export default {
  components: {
    ChatLayout
  },
  props: {
    title: {
      type: String,
      default: 'Asistente'
    },
    assistantId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      userMessage: '',
      historyMessages: [],
      threadId: '',
      waitingMessage: false
    }
  },
  async mounted() {
    const savedThreadId = localStorage.getItem('threadIdPESV')
    if (!savedThreadId) {
      this.threadId = await newThread()
      localStorage.setItem('threadIdPESV', this.threadId)
    } else {
      this.threadId = savedThreadId
    }
  },
  methods: {
    async sendMessage() {
      const userMessageObj = { role: 'user', content: this.userMessage }
      this.historyMessages.push(userMessageObj)
      this.userMessage = ''
      this.waitingMessage = true
      const response = await uploadMessage(this.threadId, this.assistantId, userMessageObj.content)
      const { id : resultId } = await response.json()
      const content = await getMessage(this.threadId, resultId)
      const systemMessageObj = { role: 'assistant', content }
      this.historyMessages.push(systemMessageObj)
      this.waitingMessage = false
    }
  }
}

</script>

<template>
  <ChatLayout 
    v-model="userMessage"
    :title="title"
    :waitingMessage="waitingMessage"
    :historyMessages="historyMessages" 
    @keyup.enter="sendMessage" />
</template>